import React from "react";
import moment from "moment";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import db from "js/db";
import { ds } from "js/core/models/dataService";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { getStaticUrl } from "js/config";
import { app } from "js/namespaces";
import * as analytics from "js/analytics";
import { User } from "js/core/models/user";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { CHECKOUT_DIALOG_CONTROL_ID, CHECKOUT_DIALOG_A_ID, CHECKOUT_DIALOG_B_ID, CHECKOUT_DIALOG_C_ID } from "common/constants";
import CreateWorkspaceDialog from "js/react/views/UserOptions/Billing/CreateWorkspaceDialog";
import EnterpriseInviteDialog from "js/react/views/UserOptions/Billing/EnterpriseInviteDialog";
import { RemoveSplashScreen } from "js/editor/SplashScreen";
import CheckoutDialog from "js/react/views/UserOptions/Billing/CheckoutDialog";
import CheckoutDialogA from "js/react/views/UserOptions/Billing/CheckoutDialogA";
import CheckoutDialogB from "js/react/views/UserOptions/Billing/CheckoutDialogB";
import CheckoutDialogC from "js/react/views/UserOptions/Billing/CheckoutDialogC";
import getLogger, { LogGroup } from "js/core/logger";
import {
    ShowWarningDialog
} from "js/react/components/Dialogs/BaseDialog";

import "css/billing.scss";
import "css/dialogs.scss";
import "css/components.scss";
import "css/controls.scss";

const CheckoutDistribution = ({ defaultBillingInterval, displayName, priceId, checkoutModalId, closeDialog = () => { }, hideBackdrop = true }) => {
    const props = {
        hideBackdrop,
        defaultBillingInterval,
        productDisplayName: displayName,
        priceId: priceId,
        checkoutModalId,
        closeDialog
    };

    // We are planning on tracking only the year plan for now, but if a user wants to switch to monthly, we will show them the old checkout dialog
    if (defaultBillingInterval === "month") {
        delete props.checkoutModalId;
        return (<CheckoutDialog {...props} />);
    }

    switch (checkoutModalId) {
        case CHECKOUT_DIALOG_A_ID:
            return (<CheckoutDialogA {...props} />);
        case CHECKOUT_DIALOG_B_ID:
            return (<CheckoutDialogB {...props} />);
        case CHECKOUT_DIALOG_C_ID:
            return (<CheckoutDialogC {...props} />);
        case CHECKOUT_DIALOG_CONTROL_ID:
        default:
            return (<CheckoutDialog {...props} />);
    }
};

const logger = getLogger(LogGroup.USER);

class Checkout extends React.Component {
    state = {
        loading: true
    };

    async componentDidMount() {
        // user
        // sign into all Firebase app instances
        await db.updateCurrentUser(this.props.firebaseUser);

        // load the User model
        app.user = new User({ id: this.props.firebaseUser.uid }, { autoLoad: false });
        ds.checkoutSetup();
        try {
            await app.user.load();
            await analytics.identify(this.props.firebaseUser);
            RemoveSplashScreen(true);
            this.setState({ loading: false });
        } catch (err) {
            logger.error(err, "[Checkout] app.user.load(); failed", { id: this.props.firebaseUser.uid });
            ShowWarningDialog({
                title: "An error occurred while loading user data",
            });
        }
    }

    renderCheckoutForm = () => {
        if (this.state.loading) {
            return <div></div>;
        }

        const { plan, billingInterval, displayName, priceId, quantity, enterpriseInviteId, closeDialog, hideBackdrop } = this.props;
        switch (plan ?? "pro") {
            case "pro":
                return (
                    <CheckoutDistribution
                        checkoutModalId={app.user.get("checkoutModalId")}
                        defaultBillingInterval={billingInterval}
                        productDisplayName={displayName}
                        priceId={priceId}
                        closeDialog={closeDialog}
                        hideBackdrop={hideBackdrop}
                    />
                );
            case "team":
                return (
                    <CreateWorkspaceDialog
                        now={moment()}
                        hideBackdrop={hideBackdrop}
                        defaultBillingInterval={billingInterval}
                        productDisplayName={displayName}
                        priceId={priceId}
                        quantity={quantity}
                        closeDialog={closeDialog}
                    />
                );
            case "enterprise":
                return (
                    <EnterpriseInviteDialog
                        hideBackdrop={hideBackdrop}
                        enterpriseInviteId={enterpriseInviteId}
                        closeDialog={() => { }}
                    />
                );
            default:
                //MuiThemeProvider expects its children to not be null so returning an empty div
                return <div></div>;
        }
    };

    render() {
        return (
            <MuiThemeProvider theme={dialogTheme}>
                <div style={{
                    height: "100vh",
                    background: `url(${getStaticUrl("/images/onboarding/slide-grid.jpg")}) no-repeat top right`,
                    backgroundSize: "962px 1198px",
                }}>
                    {this.renderCheckoutForm()}
                </div>
            </MuiThemeProvider>
        );
    }
}

export default withFirebaseUser(Checkout);
