import React from "react";
import { Icon, Link } from "@material-ui/core";
import styled, { css } from "styled-components";
import classNames from "classnames";
import moment from "moment";

import { DialogTitle, MenuItem, Select } from "@material-ui/core";

import { app } from "js/namespaces";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

import { Gap10, Gap30, Gap5 } from "js/react/components/Gap";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import Spinner from "js/react/components/Spinner";

import PaymentForm from "./PaymentForm";

import "css/billing.scss";
import { Divider } from "js/react/components/UiComponents";
import { CheckoutDialog, TermsOfUseAndRefundPolicy } from "./CheckoutDialog";

const TRIAL_TIME = 14;

const RowFlex = css`
  display: flex;
  flex-direction: row;  
`;

const Row = styled.div`
  ${RowFlex};
`;

const StyledDialogTitle = styled(DialogTitle)`
  &&& {
    padding: 40px 34px 10px 34px;
  }
`;

const PriceComparisonContainer = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap:10px;
  `;

const PriceComparisonRow = styled.div`
  ${RowFlex};
  justify-content: space-between;
`;

const PlanName = styled.span`
  font-weight: 600;
  line-height: 150%;
`;

const AmountPaid = styled.span`
  font-weight: 600;
  line-height: 150%;
`;

const AmountPaidContainer = styled.div`
  ${RowFlex};
  gap: 10px;
`;

const AmountSavedContainer = styled.div`
  ${RowFlex};
  gap: 5px;
`;

const PaymentDue = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.5px;
  color: #222222;
  text-transform: capitalize;
`;

const DueDate = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; 
  color: #999999;
`;

const ChangePlanSection = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  color: #666666;
`;

const StyledLink = styled(Link)`
  &&& {
  font-size: ${props => props.isYearly ? "12px" : "16px"};
  font-style: normal;
    font-weight: 400; 
    line-height: 150%;
    text-decoration-line: underline;
    color: #11A9E2
  }`;

const StyledCancelAnytimeText = styled.span`
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 125%;
`;

const StyledCancelAnytimeTextBold = styled.span`
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 125%;
`;

class CheckoutDialogB extends CheckoutDialog {
    constructor(props) {
        super(props);
    }

    get nextBillingDate() {
        const { billingInterval: billingIntervalState, hasTakenTrial } = this.state;

        //
        if (hasTakenTrial) {
            if (billingIntervalState === "year") {
                return moment()
                    .add(1, "year")
                    .format("MMMM Do, YYYY");
            }

            return moment()
                .add(1, "month")
                .format("MMMM Do, YYYY");
        }

        return this.trialExpirationDate;
    }

    get modalTitle() {
        const { hasTakenTrial } = this.state;
        return hasTakenTrial ? "Beautiful.ai Pro" : `Start your Beautiful.ai ${TRIAL_TIME}-day trial`;
    }

    get billingInterval() {
        const { billingInterval } = this.state;
        return billingInterval;
    }

    get submitLabel() {
        const { hasTakenTrial } = this.state;
        return hasTakenTrial ? "Get started" : "Start Free Trial";
    }

    get isSubmitting() {
        const { isSubmitting } = this.state;
        return isSubmitting;
    }

    get totalSavings() {
        return 100 - Math.round(((this.calculateTotalPayment(true)) / (this.totalYearlyOnMonthlySubscription)) * 100);
    }

    toggleBillingInterval = () => {
        const { billingInterval } = this.state;
        this.setState({ billingInterval: billingInterval === "month" ? "year" : "month" });
    };

    calculateTotalPayment = (isYearly, trial = false) => {
        const { prices } = this.state;

        if (trial) return 0;

        return isYearly ? prices.year.unit_amount / 100
            : prices.month.unit_amount / 100;
    }

    get totalYearlyOnMonthlySubscription() {
        const { prices } = this.state;
        return prices.month.unit_amount * 12 / 100;
    }

    payPerMonth = isYearly => {
        const { prices } = this.state;
        return isYearly ? prices.year.unit_amount / 12 / 100
            : prices.month.unit_amount / 100;
    }

    yearComparison = isYearly => {
        const { productDisplayName, taxAmount } = this.state;

        const taxTodayString = (taxAmount && !this.showTrial) ? " plus tax" : "";
        const taxNextBillingCycleString = taxAmount ? " plus tax" : "";

        return (
            <>
                <PriceComparisonContainer>
                    <PriceComparisonRow>
                        <PlanName>
                            {isYearly ? "" : "Monthly"} {productDisplayName} Plan
                        </PlanName>

                        <AmountPaidContainer>
                            <AmountPaid>
                                ${this.calculateTotalPayment(isYearly)} / {this.billingInterval}
                            </AmountPaid>
                        </AmountPaidContainer>

                    </PriceComparisonRow>
                    <PriceComparisonRow>
                        <AmountSavedContainer>
                            <div>
                                ${isYearly
                                    ? `${this.payPerMonth(isYearly)} x 12 months`
                                    : `${this.payPerMonth(isYearly)} x 1 month`}
                            </div>
                        </AmountSavedContainer>
                    </PriceComparisonRow>
                </PriceComparisonContainer>
                <Gap10 />
                <Divider margin={10} color="#CCC" />
                <Gap10 />
                <PriceComparisonContainer>
                    <PriceComparisonRow>
                        <PaymentDue>
                            Due today
                        </PaymentDue>
                        <PaymentDue>
                            ${this.calculateTotalPayment(isYearly, this.showTrial)}{taxTodayString}
                        </PaymentDue>
                    </PriceComparisonRow>
                    {this.showTrial && (<PriceComparisonRow>
                        <DueDate>
                            Due {this.nextBillingDate}
                        </DueDate>
                        <DueDate>
                            ${this.calculateTotalPayment(isYearly)}{taxNextBillingCycleString}
                        </DueDate>
                    </PriceComparisonRow>)}
                </PriceComparisonContainer>
            </>);
    }

    paymentDetailsExplanation = () => {
        const text = this.showTrial
            ? "Cancel anytime during your trial and you won’t be charged. Your subscription will automatically"
            : "Your subscription will automatically";

        return (<StyledCancelAnytimeText>
            {text}&nbsp;
            <StyledCancelAnytimeTextBold>
                renew {this.billingInterval === "year" ? "annually" : "monthly"}.&nbsp;
            </StyledCancelAnytimeTextBold>
            <TermsOfUseAndRefundPolicy />
        </StyledCancelAnytimeText>);
    }

    render() {
        const { successfulPurchase, prices, productDisplayName, alreadySubscribed, availableReferralCredit } = this.state;

        if (!prices || alreadySubscribed) {
            return null;
        }

        const fullPrice = this.amount;
        const price = Math.max(fullPrice - (availableReferralCredit / 100), 0);

        const priceId = this.state.prices[this.billingInterval]?.id;

        if (successfulPurchase) {
            return this.getSuccessfulPurchaseDialog(productDisplayName);
        } else {
            return this.showTrial ? (
                <BeautifulDialog
                    preventClose={this.isSubmitting}
                    closeDialog={this.props.closeDialog}
                    hideBackdrop={this.props.hideBackdrop}
                    style={this.props.style}
                    PaperProps={{ style: { maxHeight: "100%", margin: 0 } }}
                >
                    <StyledDialogTitle>
                        {this.modalTitle}
                    </StyledDialogTitle>
                    <DialogContent>
                        <div
                            id="checkout_form_container"
                            className={classNames({
                                disabled: this.isSubmitting
                            })}
                        >
                            {this.isSubmitting && <Spinner />}
                            <Gap10 />
                            {this.yearComparison(this.billingInterval === "year")}
                            {this.billingInterval === "month" &&
                                <>
                                    <Gap10 />
                                    <Row><StyledLink
                                        isYearly={false}
                                        component="button"
                                        variant="body2"
                                        onClick={this.toggleBillingInterval}
                                    >Save {this.totalSavings}% with annual billing</StyledLink>
                                    </Row>
                                </>
                            }

                            <Gap30 />
                            {priceId && <PaymentForm
                                titleStyle={{
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "120%", /* 21.6px */
                                    letterSpacing: "0.5px",
                                    color: "#222"
                                }}
                                gapElement={<Gap10 />}
                                priceId={priceId}
                                customerType="individual"
                                onSuccess={this.handleOnSuccess}
                                onBeforeSubmit={this.handleOnBeforeCharge}
                                onFailure={this.handleOnFailure}
                                onCancel={app.isConstrained && this.props.closeDialog}
                                submitLabel={this.submitLabel}
                                submitButtonStyle={{ marginTop: 30 }}
                                hasTakenTrial={!this.showTrial}
                                onPromoChanged={this.handlePromoChanged}
                                paymentDetails={this.paymentDetailsExplanation()}
                                onTaxCalculated={this.handleTaxCalculated}
                            />}
                        </div>
                    </DialogContent>
                </BeautifulDialog>
            ) : (<BeautifulDialog
                preventClose={this.isSubmitting}
                closeDialog={this.props.closeDialog}
                hideBackdrop={this.props.hideBackdrop}
                style={this.props.style}
                PaperProps={{ style: { maxHeight: "100%", margin: 0 } }}
            >
                <DialogTitle>
                    Upgrade to Beautiful.ai <span color="#11a9e2">{productDisplayName}</span>
                </DialogTitle>
                <DialogContent>
                    <div
                        id="checkout_form_container"
                        className={classNames({
                            disabled: this.isSubmitting
                        })}
                    >
                        {this.isSubmitting && <Spinner />}
                        <h3>
                            We're sorry but you are not eligible for a 14-day free trial because you have
                            already had a trial in the past.
                        </h3>
                        <Gap10 />
                        {prices.month && (
                            <>
                                <Gap5 />
                                <div className="label">Choose Plan</div>

                                <Select
                                    SelectDisplayProps={{ style: { textTransform: "capitalize" } }}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    value={this.billingInterval}
                                    inputProps={{ className: "select-input" }}
                                    onChange={event => {
                                        this.setState({
                                            billingInterval: event.target.value
                                        });
                                    }}
                                >
                                    <MenuItem className="menu-item-with-badge" value="year">
                                        Annual Plan - ${prices.year.unit_amount / 12 / 100}/month
                                        <div className="menu-item-badge">Best Value</div>
                                    </MenuItem>
                                    <MenuItem value="month">Monthly Plan - ${prices.month.unit_amount / 100}/month</MenuItem>
                                </Select>
                            </>
                        )}

                        {!prices.month && (
                            <b>Annual Plan - ${prices.year.unit_amount / 12 / 100}/month</b>
                        )}

                        <Gap10 />

                        {priceId && <PaymentForm
                            productName="pro"
                            priceId={priceId}
                            customerType="individual"
                            onSuccess={this.handleOnSuccess}
                            onBeforeSubmit={this.handleOnBeforeCharge}
                            onFailure={this.handleOnFailure}
                            onCancel={app.isConstrained && this.props.closeDialog}
                            submitLabel="Upgrade Now"
                            submitButtonStyle={{ marginTop: 30 }}
                            hasTakenTrial={true}
                            onPromoChanged={this.handlePromoChanged}
                            onTaxCalculated={this.handleTaxCalculated}
                        />}

                        <Gap10 />

                        <h3 style={{ fontSize: 14 }}>
                            You will be charged <strong>${price.toFixed(2)}</strong> today. Your next bill will be due on{" "}
                            <strong>{this.nextBillingDate}</strong>.&nbsp;
                            <TermsOfUseAndRefundPolicy />
                        </h3>

                        {availableReferralCredit > 0 &&
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <Icon style={{ color: "#fa0", fontSize: "1.25em", verticalAlign: "sub" }}>monetization_on</Icon>{" "}
                                You saved <b>${Math.min(availableReferralCredit / 100, fullPrice)}</b> from referral credit.
                            </div>
                        }
                    </div>
                </DialogContent>
            </BeautifulDialog>
            );
        }
    }
}

export default withFirebaseUser(CheckoutDialogB);
