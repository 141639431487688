import React, { Component } from "react";
import styled from "styled-components";
import { browserHistory } from "js/react/history";
import { v4 as uuid } from "uuid";
import {
    DialogTitle,
    TextField,
    Select,
    MenuItem,
} from "@material-ui/core";

import { isPPTAddin } from "js/config";
import Icon from "js/react/components/Icon";
import {
    BeautifulDialog,
    DialogContent, ShowDialog,
} from "js/react/components/Dialogs/BaseDialog";
import AddTeamMembers from "js/react/components/AddTeamMembers";
import { TEAM_USER_ROLES, TEAM_USER_LICENSE_STATUS, AnalyticsRolesAndLicensesMap } from "common/constants";
import { _ } from "js/vendor";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { emailRegex } from "js/core/utilities/regex";
import { Gap10, Gap20 } from "js/react/components/Gap";
import PaymentForm from "js/react/views/UserOptions/Billing/PaymentForm";
import SuccessfulPurchase from "js/react/views/UserOptions/Billing/SuccessfulPurchase";
import { BEAUTIFUL_WORKSPACE_ID, BEAUTIFUL_WORKSPACE_ACTION, WorkspaceAction } from "common/constants";
import BillingDataService from "js/react/views/UserOptions/dataservices/billingDataService";
import Spinner from "js/react/components/Spinner";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { User } from "js/core/models/user";
import getLocalStorage from "js/core/utilities/localStorage";
import Api from "js/core/api";

import "css/billing.scss";

import { TermsOfUseAndRefundPolicy } from "./CheckoutDialog";

const localStorage = getLocalStorage();

const StyledDialogContent = styled(DialogContent)`
    &&& {
        display: flex;
        padding-bottom: 24px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 40px;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    &&& {
        padding-left: 0;
        padding-bottom: 10px;
        padding-top: 0px;
        padding-right: 0px;

        div {
            display: flex;
            align-content: center;

            i {
                color: #11a9e2;
                margin-right: 15px;
            }
        }
    }
`;

const WorkspaceSettingsContainer = styled.div`
    margin-right: 40px;
`;

const PaymentDetailsContainer = styled.div`
    margin-top: 0px;
`;

const TeamNameContainer = styled.div`
    margin-bottom: 20px;
    width: 100%;
`;

const TeamNameField = styled(TextField)`
    width: 90%;
`;

const InterestedInCustomTrialContainer = styled.div`
    color: #6d6d6d;
    letter-spacing: 0.1px;
    margin-top: 15px;
    font-size: 14px;
    width: 100%;
    text-align: right;
`;

const TieredPriceInfoContainer = styled.div`
    font-size: 15px;
    display: block;
    color: #777;
`;

const PriceInfoContainer = styled.span`
    font-size: 15px;
    display: block;
    color: #777;
`;

const BottomTextContainer = styled.div`
  color: #777;
  font-size: 12px;
  width: 100%;
  text-align: right;
`;

const urlParams = new URLSearchParams(window.location.search);
const skipTrial = urlParams.has("t");

class CreateWorkspaceDialog extends Component {
    state = {
        proratedDate: null,
        trialExpirationDate: null,
        teamName: "",
        members: [],
        successfulPurchase: false,
        isSubmitting: false,
        hasTakenTeamTrial: false,
        errorCompanyNameMissing: false,
        //initialize with 1 to account for the user purchasing the plan
        seatCount: 1,
        taxAmountPerSeat: null
    };

    constructor(props) {
        super(props);
        app.user = new User({ id: props.firebaseUser.uid }, { autoLoad: false });
        this.state.billingInterval = props.defaultBillingInterval ?? "year";
        this.state.seatCount = props.quantity ?? 1;
    }

    async componentDidMount() {
        await app.user.loadMinimum();

        // fetch pricing from Stripe
        let prices;
        if (this.props.priceId && this.props.priceId.includes(" ")) {
            const priceIds = this.props.priceId.split(" ");

            const priceData = [
                await Api.prices.get({ id: priceIds[0] }),
                await Api.prices.get({ id: priceIds[1] })
            ];

            const month = priceData.find(p => p.recurring.interval === "month");
            const year = priceData.find(p => p.recurring.interval === "year");

            if (!month || !year) {
                throw new Error("expected monthly and yearly prices'");
            }

            prices = {
                month,
                year,
            };
        } else if (this.props.priceId) {
            prices = {
                year: await Api.prices.get({ id: this.props.priceId })
            };
        } else {
            const priceIds = BillingDataService.getDefaultPriceIds("team");
            prices = {
                month: await Api.prices.get({ id: priceIds.month }),
                year: await Api.prices.get({ id: priceIds.year })
            };
        }

        const { now } = this.props;
        let futureDate = now.clone().add(1, "year");
        const hasTakenTeamTrial = skipTrial || app.user.get("hasTakenTeamTrial") || false;
        this.setState({
            prices,
            productDisplayName: this.props.productDisplayName ?? prices.year.nickname.substring(0, prices.year.nickname.indexOf("-")),
            hasTakenTeamTrial,
            proratedDate: futureDate.diff(now, "days"),
            trialExpirationDate: now.clone().add(14, "day"),
            members: [{
                id: app.user.id,
                email: app.user.getEmail(),
                role: TEAM_USER_ROLES.OWNER,
                isDisabled: true
            }, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.LIBRARIAN,
            }, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.MEMBER_LICENSED,
            }, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.MEMBER_LICENSED,
            }, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.MEMBER_LICENSED,
            }, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.MEMBER_LICENSED,
            }]
        });

        const billingInterval = this.state.billingInterval;
        const price = prices[billingInterval];

        const params = new URLSearchParams(window.location.search);
        const testId = params.get("ex");

        const createdProps = {
            billing_term: billingInterval,
            price_id: price.id,
            plan_display_name: this.state.productDisplayName,
            min_quantity: this.props.quantity,
            test_id: testId,
        };
        trackActivity("Organization", "BillingIntentStart", testId, null, createdProps, { audit: false });
    }

    get amount() {
        const { billingInterval, promo, prices, seatCount } = this.state;

        const price = prices[billingInterval];

        let subtotal;
        if (price.billing_scheme === "tiered") {
            if (price.tiers_mode === "graduated") {
                let seats = seatCount;
                subtotal = 0;
                let prevTierUpTo = 0;
                for (const tier of price.tiers) {
                    if (seats === 0) {
                        break;
                    }
                    if (tier.up_to === null) {
                        // final tier
                        subtotal += tier.unit_amount * seats;
                        seats = 0;
                    } else {
                        const maxSeatsInTier = tier.up_to - prevTierUpTo;
                        const tierSeats = maxSeatsInTier - Math.max(0, maxSeatsInTier - seats);
                        seats -= tierSeats;
                        subtotal += tier.unit_amount * tierSeats;
                        subtotal += tier.flat_amount;
                        prevTierUpTo = tier.up_to;
                    }
                }
            } else {
                throw new Error("unsupported tiers_mode: " + price.tiers_mode);
            }
        } else {
            subtotal = price.unit_amount * seatCount;
        }

        const dollars = subtotal / 100;
        return BillingDataService.applyCoupon(dollars, promo);
    }

    get numTieredSeats() {
        const { billingInterval, prices, seatCount } = this.state;
        const price = prices[billingInterval];

        let seats = seatCount;
        let numSeatsFromTiers = 0;
        for (const tier of price.tiers) {
            if (seats === 0) {
                break;
            }
            seats = Math.max(0, seatCount - tier.up_to);
            numSeatsFromTiers = tier.up_to;
        }

        return Math.max(seats, numSeatsFromTiers);
    }

    handleChangeTeamName = event => {
        this.setState({ teamName: event.target.value, errorCompanyNameMissing: false });
    }

    handleMemberUpdate = (id, propKey, propValue) => {
        const { quantity } = this.props;

        this.setState(prevState => {
            const members = prevState.members.map(member => member.id === id ? { ...member, [propKey]: propValue } : { ...member });

            // The seat count equals the number of members that have their email filled out AND do not have the Free Member role
            let seatCount = members.filter(member => member.email.length > 1 && member.role !== TEAM_USER_ROLES.MEMBER).length;
            if (quantity) {
                seatCount = Math.max(seatCount, quantity);
            }

            return {
                ...prevState,
                members,
                seatCount
            };
        });
    };

    handleAddMember = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                members: prevState.members.concat({
                    id: uuid(),
                    email: "",
                    role: TEAM_USER_ROLES.MEMBER_LICENSED
                })
            };
        });
    };

    handleRemoveMember = id => {
        this.setState(prevState => {
            const members = prevState.members.filter(member => member.id !== id);

            //The seat count equals the number of members that have their email filled out AND do not have the Free Member role
            let seatCount = members.filter(member => member.email.length > 1 && member.role !== TEAM_USER_ROLES.MEMBER).length;
            if (this.props.quantity) {
                seatCount = Math.max(seatCount, this.props.quantity);
            }

            return {
                ...prevState,
                members,
                seatCount
            };
        });
    };

    handleSuccess = async response => {
        const { members, seatCount, teamName, trialExpirationDate, hasTakenTeamTrial, billingInterval, prices } = this.state;
        const { orgId, sharedTheme } = response;
        const price = prices[billingInterval];

        const createdProps = {
            workspace_id: orgId,
            organization_name: teamName,
            price_id: price.id,
            plan_display_name: this.state.productDisplayName,
            min_quantity: this.props.quantity
        };
        trackActivity("Organization", "Created", null, null, createdProps, { audit: true });
        await Api.klaviyoTrack.post({
            eventName: "Organization:Created",
            createdProps,
        });

        const params = new URLSearchParams(window.location.search);
        const testId = params.get("ex");

        const billingProps = {
            workspace_id: orgId,
            seats_added: seatCount,
            type: "first_purchase",
            seat_recipients: members.map(m => m.email),
            billing_term: billingInterval,
            total_seats: seatCount,
            promo_code: this.state.promo?.code,
            price_id: price.id,
            plan_display_name: this.state.productDisplayName,
            min_quantity: this.props.quantity,
            test_id: testId
        };
        trackActivity("Organization", "BillingIntentComplete", testId, null, billingProps, { audit: true });
        await Api.klaviyoTrack.post({
            eventName: "Organization:BillingIntentComplete",
            billingProps,
        });

        const inviteProps = {
            workspace_id: orgId,
            recipients: members.map(m => m.email),
            user_roles: members.map(m => AnalyticsRolesAndLicensesMap[m.role]),
            user_license: members.map(m => {
                if (m.role === TEAM_USER_ROLES.MEMBER) {
                    return TEAM_USER_LICENSE_STATUS.FREE;
                }
                return TEAM_USER_LICENSE_STATUS.TEAM_PRO;
            })
        };
        trackActivity("Organization", "InviteSent", null, null, inviteProps, { audit: true });

        const themeProps = {
            workspace_id: orgId,
            theme_id: sharedTheme.id
        };
        trackActivity("OrgTheme", "Created", null, null, themeProps, { audit: true });

        // prevent tour for team owner, appcues will serve its own
        app.user.update({
            hasTakenTour: true,
            // We do not want to preset <ChoosePlan> again for the team owner as they already confirmed the plan
            didConfirmPlan: true
        });

        const dialog = ShowDialog(SuccessfulPurchase, {
            productDisplayName: this.state.productDisplayName,
            callToActionText: "Continue",
            message: "You've signed up for the Beautiful.ai Team Plan!",
            showTrial: !hasTakenTeamTrial,
            trialExpirationDate: trialExpirationDate.format("MMMM Do YYYY"),
            onCallToActionClick: () => {
                if (isPPTAddin) {
                    this.props.closeDialog(true);
                } else {
                    browserHistory.push("/");
                    dialog.close();
                }
            }
        });

        // switch to the new org
        localStorage.setItem(BEAUTIFUL_WORKSPACE_ID, orgId);
        localStorage.setItem(BEAUTIFUL_WORKSPACE_ACTION, WorkspaceAction.CREATED);

        trackActivity("Organization", "Joined", null, null, { workspace_id: orgId });
        this.setState({ successfulPurchase: true });
    };

    handleBeforeCharge = () => {
        const { teamName } = this.state;
        if (teamName.length === 0) {
            this.setState({ errorCompanyNameMissing: true });
            return false;
        }

        this.setState({ isSubmitting: true });
        return true;
    }

    handleFailure = () => {
        this.setState({ isSubmitting: false });
    }

    handlePromoChanged = promo => {
        this.setState({ promo });
    }

    handleTaxCalculated = taxAmount => {
        this.setState({ taxAmountPerSeat: taxAmount });
    }

    render() {
        const { now, hideBackdrop, quantity } = this.props;
        const {
            successfulPurchase,
            billingInterval,
            isSubmitting,
            teamName,
            members,
            message,
            seatCount,
            hasTakenTeamTrial,
            trialExpirationDate,
            errorCompanyNameMissing,
            prices,
            taxAmountPerSeat
        } = this.state;

        if (!this.state.prices) {
            return null;
        }

        const taxPerSeat = (taxAmountPerSeat || 0) / 100;
        const pricePerSeatWithTax = (this.amount / seatCount).toFixed(2) + (taxPerSeat ? ` plus tax` : "");
        const totalPriceWithTax = (this.amount + taxPerSeat * seatCount).toFixed(2);

        const price = prices[billingInterval];

        const priceId = price.id;

        const canSubmit = taxAmountPerSeat !== null &&
            !members.some(member => member.email.length > 0 && !emailRegex.test(member.email)) &&
            !members.some(member => members.some(otherMember => member.email && member.email.toLowerCase() === otherMember.email.toLowerCase() && member.id !== otherMember.id));

        return (
            <BeautifulDialog
                maxWidth="md"
                fullWidth={false}
                hideBackdrop={hideBackdrop}
                closeDialog={this.props.closeDialog}
                PaperProps={{ style: { maxHeight: "100%", margin: 0 } }}
            >
                {isSubmitting && <Spinner />}
                <StyledDialogContent>
                    <WorkspaceSettingsContainer>
                        <StyledDialogTitle>
                            <div>
                                <Icon
                                    iconName="domain"
                                />
                                Create a Team
                            </div>
                        </StyledDialogTitle>
                        <TeamNameContainer>
                            <TeamNameField
                                autoFocus
                                onChange={this.handleChangeTeamName}
                                variant="standard"
                                name="Company Name"
                                label="Company Name (required)"
                            />
                        </TeamNameContainer>
                        <div style={{ marginBottom: "20px" }}>
                            <AddTeamMembers
                                billingTerm={billingInterval}
                                members={members}
                                onUpdateMember={this.handleMemberUpdate}
                                onAddMember={this.handleAddMember}
                                onRemoveMember={this.handleRemoveMember}
                                showLicenseDropdown={false}
                            />
                        </div>
                    </WorkspaceSettingsContainer>

                    <PaymentDetailsContainer>
                        {prices.month &&
                            <>
                                <div className="label">Choose your plan</div>
                                <Select
                                    SelectDisplayProps={{ style: { textTransform: "capitalize" } }}
                                    style={{ width: 400 }}
                                    variant="outlined"
                                    value={billingInterval}
                                    inputProps={{ className: "select-input" }}
                                    disableUnderline
                                    onChange={event => {
                                        let futureDate = now.clone().add(1, event.target.value);
                                        this.setState({
                                            billingInterval: event.target.value,
                                            proratedDate: futureDate.diff(now, "days")
                                        });
                                    }}
                                    renderValue={value => {
                                        if (value === "year") {
                                            return `Annual Plan - $${prices.year.unit_amount / 12 / 100} /month`;
                                        }
                                        return `Monthly Plan - $${prices.month.unit_amount / 100}/month`;
                                    }}
                                >
                                    <MenuItem
                                        value="year"
                                    >
                                        Annual Plan - ${prices.year.unit_amount / 12 / 100}/month per Team Pro Seat
                                    </MenuItem>
                                    <MenuItem value="month">
                                        Monthly Plan - ${prices.month.unit_amount / 100}/month per Team Pro Seat
                                    </MenuItem>
                                </Select >
                            </>
                        }
                        {!prices.month &&
                            <b>{this.state.productDisplayName} - Annual Plan</b>
                        }

                        <Gap10 />

                        <div>
                            {price?.billing_scheme === "tiered" &&
                                <>
                                    <TieredPriceInfoContainer>
                                        {quantity > 0 && (
                                            <div>Includes first {quantity} seats.</div>
                                        )}
                                        {price.tiers.map((tier, i) => {
                                            if (tier.up_to !== null) {
                                                if (tier.up_to <= quantity ?? 0) {
                                                    return null;
                                                }
                                                return (
                                                    <div style={{ lineHeight: 1.5 }}>
                                                        {i === 0 ? "First " + tier.up_to : "Next " + (tier.up_to - price.tiers[i - 1].up_to)} seats: {i === 0 ? "" : "+"}${tier.flat_amount / 100}/year
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div style={{ lineHeight: 1.5 }}>
                                                        Then: +${tier.unit_amount / 100}/year per seat
                                                    </div>
                                                );
                                            }
                                        })}
                                    </TieredPriceInfoContainer>
                                    <PriceInfoContainer>
                                        Your plan will cost <strong>${this.amount.toFixed(2)}/{billingInterval}</strong> and includes {this.numTieredSeats} seats.&nbsp;
                                        <TermsOfUseAndRefundPolicy />
                                    </PriceInfoContainer>
                                </>
                            }
                            {price?.billing_scheme !== "tiered" && (
                                <PriceInfoContainer>
                                    You are adding a total of {seatCount} Pro team {"seat".pluralize(seatCount > 1)}, including your own, at <span style={{ fontWeight: 900 }}>${pricePerSeatWithTax}/{billingInterval}</span> per seat.&nbsp;
                                    <TermsOfUseAndRefundPolicy />
                                </PriceInfoContainer>
                            )}
                        </div>

                        {!successfulPurchase && (
                            <PaymentForm
                                priceId={priceId}
                                seatCount={seatCount}
                                customerType="team"
                                teamName={teamName}
                                members={members.filter(member => member.email.length)}
                                message={message}
                                amount={this.amount}
                                hasTakenTrial={hasTakenTeamTrial}
                                onSuccess={this.handleSuccess}
                                onBeforeSubmit={this.handleBeforeCharge}
                                onFailure={this.handleFailure}
                                submitLabel={hasTakenTeamTrial ? "Confirm" : "Start Trial"}
                                submitButtonStyle={{ width: "100%" }}
                                buttonsContainerStyle={{ marginTop: 20 }}
                                canSubmit={canSubmit}
                                onPromoChanged={this.handlePromoChanged}
                                onTaxCalculated={this.handleTaxCalculated}
                                errorMessage={errorCompanyNameMissing ? "Company Name is required" : null}
                                showTitle={false}
                            />
                        )}

                        <Gap20 />

                        <BottomTextContainer>
                            {!hasTakenTeamTrial && <>
                                Once your 14 day free trial ends on <span style={{ fontWeight: 900 }}>{trialExpirationDate && trialExpirationDate.format("MMMM D, YYYY")}</span>,
                                you will be charged <span style={{ fontWeight: 900 }}>${totalPriceWithTax}</span>.
                            </>}
                            {hasTakenTeamTrial && <>
                                You will be charged <span style={{ fontWeight: 900 }}>${totalPriceWithTax}</span>.
                            </>}
                            &nbsp;Your subscription will <strong>automatically renew {billingInterval == "year" ? "annually" : "monthly"}</strong>.
                            Update your billing information, add and remove seats or cancel your subscription at any time from your account management.&nbsp;
                        </BottomTextContainer>

                        {!hasTakenTeamTrial && <InterestedInCustomTrialContainer>
                            Interested in a custom trial? <a href="mailto:sales@beautiful.ai" style={{ color: "#11a9e2" }}>Contact sales</a>
                        </InterestedInCustomTrialContainer>}
                    </PaymentDetailsContainer >
                </StyledDialogContent >
            </BeautifulDialog >
        );
    }
}

export default withFirebaseUser(CreateWorkspaceDialog);
