import { AddressElement } from "@stripe/react-stripe-js";
import moment from "moment";
import React, { Component } from "react";
import styled, { css } from "styled-components";
import { v4 as uuid } from "uuid";

import { DialogTitle, TextField } from "@material-ui/core";
import { browserHistory } from "js/react/history";

import {
    AnalyticsRolesAndLicensesMap,
    BEAUTIFUL_WORKSPACE_ACTION,
    BEAUTIFUL_WORKSPACE_ID,
    TEAM_USER_LICENSE_STATUS,
    TEAM_USER_ROLES,
    WorkspaceAction
} from "common/constants";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { User } from "js/core/models/user";
import getLocalStorage from "js/core/utilities/localStorage";
import { delimiterRegex, emailRegex } from "js/core/utilities/regex";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import withElements from "js/react/views/UserOptions/Billing/withElements";
import withStripe from "js/react/views/UserOptions/Billing/withStripe";
import { guessUserCountry } from "js/react/views/UserOptions/Billing/utils";
import { TaxIdForm } from "js/react/views/UserOptions/Billing/TaxIdForm";

import AddTeamMembers from "js/react/components/AddTeamMembers";
import {
    BeautifulDialog,
    DialogContent,
    ShowErrorDialog
} from "js/react/components/Dialogs/BaseDialog";
import ErrorDialog from "js/react/components/Dialogs/ErrorDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import Icon from "js/react/components/Icon";
import { BlueButton } from "js/react/components/UiComponents";
import SuccessfulPurchase from "js/react/views/UserOptions/Billing/SuccessfulPurchase";

const logger = getLogger(LogGroup.INVITES);

const localStorage = getLocalStorage();

const paragraphCSS = css`
    font-size: 15px;
    color: #777;
    user-select: none;
    margin: 3px 0;

    >b {
        color: black;
    }

    >a {
        text-decoration: none;
        color: #11a9e2;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    &&& {
        padding-left: 40px;
        padding-bottom: 18px;
        display: flex;
        align-content: center;

        i {
            color: #11a9e2;
            margin-right: 15px;
        }
    }
`;

const StyledDialogContent = styled(DialogContent)`
    &&& {
        display: flex;
        flex-flow: column;
        padding: 0 40px 30px 40px;

        >p {
            ${paragraphCSS}
            margin: 0;
            margin-bottom: 20px;
        }

        >div {
            display: flex;
            flex-flow: row;
            gap: 30px;
        }
    }
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    gap: 15px;

    >button {
        padding: 6px 30px;
    }
`;

const InviteInfoContainer = styled.div`
    margin: 30px 0;

    >p {
        ${paragraphCSS}
    }
`;

class EnterpriseInviteDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: "loading",
            teamName: "",
            invite: null,
            address: {
                line1: "",
                line2: "",
                postal_code: "",
                country: "",
                city: "",
                state: ""
            },
            isAddressValid: false,
            members: [{
                id: props.firebaseUser.uid,
                email: props.firebaseUser.email,
                role: TEAM_USER_ROLES.OWNER,
                isDisabled: true
            }],
            taxId: "",
            taxIdType: null,
            isTaxIdValid: true,
            taxAmount: 0
        };

        this.guessedUserCountry = guessUserCountry();
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        const { isAddressValid, address, isTaxIdValid, taxId, taxIdType } = this.state;
        if (isAddressValid !== prevState.isAddressValid || isTaxIdValid !== prevState.isTaxIdValid) {
            this.calculateTaxAmount();
        } else if (isAddressValid && (address.country !== prevState.address.country || address.postal_code !== prevState.address.postal_code)) {
            this.calculateTaxAmount();
        } else if (isTaxIdValid && (taxId !== prevState.taxId || taxIdType !== prevState.taxIdType)) {
            this.calculateTaxAmount();
        }
    }

    async load() {
        const { firebaseUser, enterpriseInviteId } = this.props;
        const { members, teamName } = this.state;

        app.user = new User({ id: firebaseUser.uid }, { autoLoad: false });
        try {
            await app.user.loadMinimum();
        } catch (err) {
            logger.error(err, "[EnterpriseInviteDialog] app.user.loadMinimum() failed");

            this.setState({ status: "serverError" });
            return;
        }

        let invite;
        try {
            invite = await Api.enterpriseInvites.get({ id: enterpriseInviteId });
        } catch (err) {
            logger.error(err, "[EnterpriseInviteDialog] Api.enterpriseInvites.get() failed", { enterpriseInviteId });

            if (err.status === 403) {
                this.setState({ status: "wrongUser" });
                return;
            }

            if (err.status === 404) {
                this.setState({ status: "inviteNotFound" });
                return;
            }

            this.setState({ status: "serverError" });
            return;
        }

        this.setState({
            invite,
            status: "ready",
            teamName: invite.teamName || teamName,
            members: [
                members[0],
                ...[...new Array(Math.min(5, invite.seatCount - 1)).keys()].map(idx => ({
                    id: uuid(),
                    email: "",
                    role: idx === 0 ? TEAM_USER_ROLES.LIBRARIAN : TEAM_USER_ROLES.MEMBER_LICENSED,
                }))
            ]
        });
    }

    calculateTaxAmount = async () => {
        const {
            invite: {
                productId,
                pricePerSeat,
                seatCount
            },
            address: {
                country,
                postal_code
            },
            taxId,
            isAddressValid
        } = this.state;

        if (!isAddressValid) {
            this.setState({ taxAmount: 0 });
            return;
        }

        try {
            const { taxAmount } = await Api.taxes.post({
                amount: seatCount * pricePerSeat,
                stripeProductId: productId,
                countryCode: country,
                postalCode: postal_code,
                taxId
            });
            this.setState({ taxAmount });
        } catch (err) {
            logger.error(err, "[PaymentForm] failed to calculate tax");
            this.setState({ taxAmount: 0 });
        }
    }

    handleMemberUpdate = (id, propKey, propValue) => {
        this.setState(prevState => {
            const members = prevState.members.map(member => {
                if (member.id === id) {
                    return { ...member, [propKey]: propValue };
                }
                return { ...member };
            });

            return {
                ...prevState,
                members
            };
        });
    };

    handleAddMember = () => {
        this.setState(prevState => ({
            ...prevState,
            members: [...prevState.members, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.MEMBER_LICENSED,
            }]
        }));
    };

    handleRemoveMember = id => {
        this.setState(prevState => ({
            ...prevState,
            members: prevState.members.filter(member => member.id !== id)
        }));
    }

    handleAcceptInvite = async () => {
        const { members, address, teamName, invite, taxId, taxIdType } = this.state;

        this.setState({ status: "applyingInvite" });

        const filteredMembers = members.filter(({ email }) => !!email);

        try {
            const { orgId, priceId, stripeProductDisplayName, sharedThemeId } = await Api.enterpriseInvites.post({
                id: invite.id,
                members: filteredMembers,
                address,
                teamName,
                taxId,
                taxIdType
            });

            const createdProps = {
                workspace_id: orgId,
                organization_name: teamName,
                price_id: priceId,
                plan_display_name: stripeProductDisplayName,
                min_quantity: invite.seatCount
            };
            trackActivity("Organization", "Created", null, null, createdProps, { audit: true });
            await Api.klaviyoTrack.post({
                eventName: "Organization:Created",
                createdProps,
            });

            const inviteProps = {
                workspace_id: orgId,
                recipients: filteredMembers.map(m => m.email),
                user_roles: filteredMembers.map(m => AnalyticsRolesAndLicensesMap[m.role]),
                user_license: filteredMembers.map(m => {
                    if (m.role === TEAM_USER_ROLES.MEMBER) {
                        return TEAM_USER_LICENSE_STATUS.FREE;
                    }
                    return TEAM_USER_LICENSE_STATUS.TEAM_PRO;
                })
            };
            trackActivity("Organization", "InviteSent", null, null, inviteProps, { audit: true });

            const themeProps = {
                workspace_id: orgId,
                theme_id: sharedThemeId
            };
            trackActivity("OrgTheme", "Created", null, null, themeProps, { audit: true });

            localStorage.setItem(BEAUTIFUL_WORKSPACE_ID, orgId);
            localStorage.setItem(BEAUTIFUL_WORKSPACE_ACTION, WorkspaceAction.CREATED);

            trackActivity("Organization", "Joined", null, null, { workspace_id: orgId });
            this.setState({ status: "success" });
        } catch (err) {
            logger.error(err, "[EnterpriseInviteDialog] handleAcceptInvite() failed", { enterpriseInviteId: invite.id });
            this.setState({ status: "serverError" });
        }
    }

    handleImportMembersFromFile = file => {
        const { invite: { seatCount, id } } = this.state;

        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = err => {
                reject(err);
            };
            reader.readAsBinaryString(file);
        })
            .then(fileContent => {
                const emails = fileContent
                    .split(delimiterRegex)
                    .map(record => record.trim().toLowerCase())
                    .filter(record => emailRegex.test(record))
                    .slice(0, seatCount - 1);

                if (emails.length > 0) {
                    this.setState(state => ({
                        ...state,
                        members: [
                            state.members[0],
                            ...emails.map(email => ({
                                id: uuid(),
                                email,
                                role: TEAM_USER_ROLES.MEMBER_LICENSED
                            }))
                        ]
                    }));
                }
            })
            .catch(err => {
                logger.error(err, "[EnterpriseInviteDialog] handleImportMembersFromFile() failed", { enterpriseInviteId: id });

                ShowErrorDialog({
                    title: "Sorry, we couldn't read your file, try a different one"
                });
            });
    }

    handleAddressChange = ({ complete, value: { address, name } }) => {
        this.setState({ address: { ...address }, isAddressValid: complete, teamName: name });
    }

    handleTaxIdChange = ({ taxId, taxIdType, isTaxIdValid }) => {
        this.setState({ taxId, taxIdType, isTaxIdValid });
    }

    render() {
        const {
            hideBackdrop,
            closeDialog
        } = this.props;
        const {
            status,
            members,
            invite,
            teamName,
            isAddressValid,
            taxAmount,
            address,
            isTaxIdValid
        } = this.state;

        if (status === "loading") {
            return <FetchingClickShield visible backgroundColor="white" />;
        }

        if (status === "wrongUser") {
            return (<ErrorDialog
                error="Wrong account"
                message="You must have logged into a wrong account"
                acceptCallback={() => window.location = `/logout?continue=${encodeURIComponent(window.location.pathname + window.location.search)}`}
                acceptButtonText="Sign out"
            />);
        }

        if (status === "inviteNotFound") {
            return (<ErrorDialog
                error="Wrong link"
                message="There is something wrong with your link"
                acceptCallback={() => window.location = "/"}
                acceptButtonText="Go to library"
            />);
        }

        if (status === "serverError") {
            return (<ErrorDialog
                error="Server error"
                message="Something went terribly wrong, please contact us at support@beautiful.ai"
                acceptCallback={() => window.location = "/"}
                acceptButtonText="Go to library"
            />);
        }

        if (status === "success") {
            return (<SuccessfulPurchase
                productDisplayName="Enterprise"
                callToActionText="Continue"
                message="You've signed up for the Beautiful.ai Team Plan!"
                trialDurationDays={invite.trialDurationDays}
                showTrial
                trialExpirationDate={moment().add(invite.trialDurationDays, "day").format("MMMM Do YYYY")}
                onCallToActionClick={() => browserHistory.push("/")}
            />);
        }

        const isFormValid = isAddressValid && isTaxIdValid &&
            !members.some(member => member.email.length > 0 && !emailRegex.test(member.email)) &&
            !members.some(member => members.some(otherMember => member.email && member.email.toLowerCase() === otherMember.email.toLowerCase() && member.id !== otherMember.id)) &&
            teamName;

        const invitedBy = invite.invitedByDisplayName ? `${invite.invitedByDisplayName} (${invite.invitedByEmail})` : invite.invitedByEmail;

        const taxString = taxAmount ? ` plus $${taxAmount} tax` : "";

        return (
            <BeautifulDialog maxWidth="md" fullWidth={false} hideBackdrop={hideBackdrop} closeDialog={closeDialog}>
                <FetchingClickShield visible={status === "applyingInvite"} backgroundColor="rgba(255,255,255,0.5)" />
                <StyledDialogTitle>
                    <Icon iconName="domain" />
                    Create a Team
                </StyledDialogTitle>
                <StyledDialogContent>
                    <p>You were invited by <b>{invitedBy}</b> to start an enterprise trial.</p>
                    <div>
                        <div>
                            <AddTeamMembers
                                members={members}
                                onUpdateMember={this.handleMemberUpdate}
                                onAddMember={this.handleAddMember}
                                onRemoveMember={this.handleRemoveMember}
                                onImportMembersFromFile={this.handleImportMembersFromFile}
                                showLicenseDropdown={false}
                                maxSeatCount={invite.seatCount}
                            />
                        </div>
                        <div>
                            <AddressElement
                                options={{
                                    mode: "billing",
                                    defaultValues: {
                                        name: teamName,
                                        address: {
                                            country: this.guessedUserCountry
                                        }
                                    },
                                    display: {
                                        name: "organization"
                                    }
                                }}
                                onChange={this.handleAddressChange}
                            />
                            <TaxIdForm
                                countryCode={address.country}
                                initialTaxId={""}
                                onChange={this.handleTaxIdChange}
                                disabled={false}
                            />
                            <InviteInfoContainer>
                                <p>
                                    Your trial is <b>{invite.trialDurationDays}</b> days and includes <b>{invite.seatCount} Pro Team seats</b>.
                                    When the trial ends you'll be billed <b>${invite.seatCount * invite.pricePerSeat}{taxString}/{invite.billingInterval}</b>.
                                </p>
                                <p>If you have questions about , contact <a href={`mailto:${invite.invitedByEmail}`}>{invite.invitedByEmail}</a></p>
                            </InviteInfoContainer>
                            <ButtonsContainer>
                                <BlueButton disabled={!isFormValid} onClick={this.handleAcceptInvite}>
                                    Create Team
                                </BlueButton>
                            </ButtonsContainer>
                        </div>
                    </div>
                </StyledDialogContent>
            </BeautifulDialog >
        );
    }
}

export default withStripe(withElements(withFirebaseUser(EnterpriseInviteDialog)));
